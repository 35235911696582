import axios from 'axios'
import qs from 'qs'

const vinApi: any = {}

vinApi.vin = (params: any): Promise<{}>=>{
  params.yc_id = '11111111111'
  return axios.get('/v1/operate/cars/vin', { params })
}
vinApi.group = (params: {}): Promise<{}>=>{
  return axios.get('/v1/operate/cars/main/group/list', { params })
}
vinApi.structure = (params: {}): Promise<{}>=>{
  return axios.get('/v1/operate/cars/structure', { params })
}
vinApi.subGroup = (params: {}): Promise<{}>=>{
  return axios.get('/v1/operate/cars/main/sub/group', { params })
}
vinApi.config = (params: {}): Promise<{}>=>{
  return axios.get('/v1/operate/cars/config/search', { params })
}
vinApi.points = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/structimgs/points", {params})
}
vinApi.partnew = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/partnew", {params})
}
vinApi.part = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/part", {params})
}

vinApi.config = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/config", {params})
}
vinApi.config = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/vin/bom", {params})
}
vinApi.configSearch = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/config/search", {params})
}
vinApi.configSearch = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/vin/bom/search", {params})
}
vinApi.tag = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/part/tags", {params})
}
vinApi.getStandardLabel = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/ugc/part/lable/get", {params})
}

vinApi.baseinfo = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/parts/baseinfo", {params})
}
vinApi.replace = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/parts/replace", {params})
}
vinApi.stocks = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/user/zhb/match/stocks/simple", params)
}
vinApi.articles = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/parts/articles", {params})
}
vinApi.applicable = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/parts/generacars", {params})
}
vinApi.pics = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/ugc/part/pics", {params})
}
vinApi.removePic = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/ugc/part/picdel", {params})
}

vinApi.getMaintenancePoint = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/maintenance/sub/group/struct", {params})
}
vinApi.getMaintenancePart = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/maintenance/sub/group/parts", {params})
}

vinApi.getVinHistory = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/epc/history/vin", {params})
}
vinApi.getPartHistory = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/user/history/parts", {params})
}

vinApi.getBrand = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/brands", {params})
}
vinApi.getSearchCount = () => {
  return axios.get('/v1/operate/user/part/search/rank')
}
vinApi.getTitle = (params: {}) => {
  return axios.get('/v1/operate/cars/model/title', {params})
}
vinApi.getModels = (params: {}, cancelToken: any): Promise<{}> => {
  return axios.get("/v1/operate/cars/models", {...{params}, ...cancelToken})
}
vinApi.ocr = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/vin/ocr", params)
}

vinApi.upkeep = (params: {}): Promise<{}> => {
  return axios.get('/v1/operate/epc/vin/oil/products', {params})
}

vinApi.getSpecialParts = (params: {}) => {
  return axios.get('/v1/operate/special/category/list_v2', {params})
}
vinApi.updateVinConfig = (params: any) => {
  return axios.post('/v1/operate/cars/vin/edit', params)
}

vinApi.updateGroup = (params: any) => {
  return axios.post('/v1/operate/cars/main/group/list/edit', params)
}

vinApi.updateStructure = (params: any) => {
  return axios.post('/v1/operate/cars/structure/edit', params)
}
vinApi.updatePartList = (params: any) => {
  return axios.post('/v1/operate/cars/part/edit', params)
}
vinApi.updateImgVer = (params: any) => {
  return axios.post('/v1/operate/cars/structure/img/switch', params)
}

vinApi.getVinConfig = (params: any) => {
  return axios.get("/v1/operate/cars/vin/config", {params})
}

vinApi.updateVinBom = (params: any) => {
  return axios.post("/v1/operate/cars/vin/bom/edit", params)
}



vinApi.group_car = (params: any) => {
  return axios.get("/v1/operate/cars/model/main/group/list", {params})
}
vinApi.structure_car = (params: any) => {
  return axios.get("/v1/operate/cars/model/structure", {params})
}
vinApi.part_car = (params: any) => {
  return axios.get("/v1/operate/cars/model/part", {params})
}
vinApi.partnew_car = (params: any) => {
  return axios.get("/v1/operate/cars/model/partnew", {params})
}
vinApi.updateGroup_car = (params: any) => {
  return axios.post("/v1/operate/cars/model/main/group/list/edit", params)
}
vinApi.updateStructure_car = (params: any) => {
  return axios.post('/v1/operate/cars/model/structure/edit', params)
}
vinApi.updatePartList_car = (params: any) => {
  return axios.post('/v1/operate/cars/model/part/edit', params)
}

vinApi.vinBind = (params: any) => {
  return axios.post('/v1/operate/cars/vin/map', params)
}

vinApi.updateCar = (params: any) => {
  return axios.post('/v1/operate/cars/model/edit', params)
}
vinApi.uploadCarImg = (params: any) => {
  return axios.post("/v1/operate/cars/model/upload", params)
}
vinApi.getCarImgs = (params: any) => {
  return axios.get("/v1/operate/cars/model/imgs/list", {params})
}
vinApi.updateCarImgs = (params: any) => {
  return axios.post("/v1/operate/cars/model/imgs/edit", params)
}
vinApi.uploadVinImg = (params: any) => {
  return axios.post("/v1/operate/cars/vin/upload", params)
}
vinApi.getVinImgs = (params: any) => {
  return axios.get("/v1/operate/cars/vin/imgs/list", {params})
}
vinApi.updateVinImgs = (params: any) => {
  return axios.post("/v1/operate/cars/vin/imgs/edit", params)
}
vinApi.ocrCarImg = (params: any) => {
  return axios.get("/v1/operate/robot/cars", {params})
}
vinApi.getVinLicense = (params: any) => {
  return axios.get("/v1/operate/cars/license/imgs/list", {params})
}
vinApi.addVinLicense = (params: any) => {
  return axios.post("/v1/operate/cars/vin/license/edit", params)
}
vinApi.delVinLicense = (params: any) => {
  return axios.post("/v1/operate/cars/license/imgs/edit", params)
}

vinApi.ocrPath = '/v1/operate/vin/ocr'
export default vinApi