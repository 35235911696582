import React, { useEffect, useState } from 'react'
import RcUpload from 'rc-upload'
import Loading from '@/components/Loading'
import { compress, dataURItoBlob } from '@/utils/utils'
import axios from 'axios'
import commonApi from '@/api/commonApi'

interface PropsIn {
  parse?: any,
  loading?: boolean,
  setLoading?: Function,
  action?: string,
  name?: string,
  data?: any,
  multiple?: boolean,
  cancelClick?: boolean,
  beforeUpload?: (file: File, FileList: File[]) => Promise<any>,
  start?: (response: Object) => void,
  success?: (response: Object, file: File, xhr: Object) => void,
  error?: (response: Object) => void,
  children?: any,
  successUrl?: (url: any) => void
}

let pasteUn = false

const Upload: React.FC<PropsIn> = (props) => {
  const { parse, loading, setLoading, action, name, data, multiple, cancelClick, beforeUpload, start, success, error, children, successUrl } = props

  const [fileLoading, setFileLoading] = useState<boolean>(false)

  useEffect(() => {
    if(parse){
      parseUpdate()
      return unmount
    }
  }, [])

  const unmount = () => {
    if(pasteUn){
      window.removeEventListener('paste', update)
    }
  }
  const parseUpdate = () => {
    window.removeEventListener('paste', update)
    window.addEventListener('paste', update)
    pasteUn = true
  }
  const update = (event: any) => {
    let clipboardData = event.clipboardData || event.originalEvent.clipboardData
    if ( !(clipboardData && clipboardData.items) ) {
      return;
    }
    let f = null;
    for (let i = 0, len = clipboardData.items.length; i < len; i++) {
      let item = clipboardData.items[i];
      if (item.type.indexOf('image') !== -1) {
        f = item.getAsFile();
      }
    }
    if(f) {
      fileReaderupdateImg(f)
    }
  }
  const fileReaderupdateImg = (file: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    
    reader.onload = (e: any) => {
      let img: any = new Image()
      img.src = e.target.result
      img.onload = () => {
        let data = compress(img)
        let blob = dataURItoBlob(data)
        let formData = new FormData()
        formData.append((name || 'file'), blob)
        setLoading && setLoading(true)
        parse(formData).then((result: any)=>{
          setLoading && setLoading(false)
          success && success(result, file, {})
        })
      }
    }
  }

  const fileUpload = (e: any) => {
    let file = e.file
    let name = e.file.name
    console.log("e:", e, 'name:', name)
    
    commonApi.getUploadObs().then((res: any) => {
      if(res.code === 1) {
        let resData = res.data
        let key = +new Date() + '.png'
        // if(uuid) {
        //   key = v4().replaceAll("-", "") + '.png'
        // } 
        let policy = resData.policy
        let signature = resData.signature
        let accessKeyId = resData.accessKeyId
        let url = resData.url + '/'
        let formData = new FormData();
        formData.append('key', key)
        formData.append('content-type', 'text/plain')
        formData.append('policy', policy)
        formData.append('signature', signature)
        formData.append('accessKeyId', accessKeyId)
        formData.append('file', file)
        setFileLoading(true)
        axios.post(url, formData, {
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); 
            // setPercent(percentCompleted)
          }
        }).then((res1: any) => {
          setFileLoading(false)
          let url = 'https://bigfiles.007vin.com/' + key
          successUrl && successUrl(url)
          
          
        }).catch((error: any) => {
          // setUploadLoading(false)
        });
      }
    })  
  }
  
  return (
    <RcUpload
      action={action}
      name={name || 'file'}
      data={data || {}}
      multiple={multiple}
      beforeUpload={beforeUpload}
      onStart={start}
      onSuccess={success}
      onError={error}
      customRequest={fileUpload}
      openFileDialogOnClick={cancelClick ? false : true}
    >
      <Loading visible={loading || fileLoading || false}>
        {children}
      </Loading>
    </RcUpload>
  )
}

export default Upload