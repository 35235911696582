import { Navigate, useRoutes } from "react-router-dom"
import { KeepAlive } from 'react-activation'
import { lazy } from 'react'
import { Login, Otest } from '@/page/Account'
import Home from '@/page/Home'

import { Model, DatastandardEngine, ModelDetail, Hang, Part, Smodel, Vinmodel, Categoryoe } from '@/page/Datastandard'

import Threedmodel, { Chassis, Wholevehicle, Carbody, ThreeEngine, Tpart, Tmodel, Tcar } from '@/page/Threedmodel'
import { Repairdoc } from '@/page/Threedmodel/Components/ThreedPart'

import User, { Role, Permission, ButtonsList } from '@/page/User'
import Pagecommon from '@/page/Pagecommon'
import Vin, { VinDetailPage } from '@/page/Dataedit/Vin'
import Vincon from "@/page/Dataedit/Vincon"


import { Topname, Stdclass, Jhname, Oeothername, GroupList } from '@/page/Namestd'
import { Vinumodel, Fourprice, Onlineservice } from '@/page/Dataoperation'

import { Datamanage } from '@/page/Maintiankonw'
import LazyLoad from "./lazyLoad"

const nav: any = [
	{
		path: "/",
		element: <Navigate to="/home"/>
  },
  {
		path: "/:conpath",
		element: <Pagecommon/>
	},
	{
		path: "/home",
		element: LazyLoad(lazy(() => import("@/page/Home"))),
	},
	{
		path: "/login",
		element: LazyLoad(lazy(() => import("@/page/Account/Login"))),
  },
	{
		path: '/otest',
		element: <Otest/>
	},

  // datastandard
  // {
	// 	path: "/datastandard/model",
	// 	element: LazyLoad(lazy(() => import("@/page/Datastandard/Model/index"))),
  // },
  // {
	// 	path: "/datastandard/model/detail",
	// 	element: LazyLoad(lazy(() => import("@/page/Datastandard/Model/Detail"))),
  // },
  {
		path: "/datastandard/engine",
		element: LazyLoad(lazy(() => import("@/page/Datastandard/EngineExcel"))),
  },
  {
		path: "/datastandard/hang",
		element: LazyLoad(lazy(() => import("@/page/Datastandard/HangExcel"))),
  },
  {
		path: "/datastandard/part",
		element: LazyLoad(lazy(() => import("@/page/Datastandard/StandardSold"))),
  },
	{
		path: "/datastandard/smodel",
		element: LazyLoad(lazy(() => import("@/page/Datastandard/Smodel"))),
  },
	{
		path: "/datastandard/vinmodel",
		element: LazyLoad(lazy(() => import("@/page/Datastandard/Vinmodel"))),
  },
	{
		path: "/datastandard/categoryoe",
		element: LazyLoad(lazy(() => import("@/page/Datastandard/Categoryoe"))),
  },
	{
    path: '/datastandard/topname',
    element: LazyLoad(lazy(() => import("@/page/Namestd/Topname")))
  },
	{
    path: '/datastandard/stdclass',
    element: LazyLoad(lazy(() => import("@/page/Namestd/Stdclass")))
  },


	// dataeidt

	{
		path: '/dataedit',
		element: <Vincon/>,
		children: [
			{
				path: '/dataedit/vin',
				element: <Vin/>
			},
			{
				path: '/dataedit/vin/detail',
				element: <VinDetailPage/>
			},
			{
				path: '/dataedit/group',
				element: <Vin/>
			},
			{
				path: '/dataedit/group/detail',
				element: <VinDetailPage/>
			},
		]
	},



  // threed
  {
		path: "/threed/:path",
		element: <Threedmodel />,
  },
  {
		path: "/threed/chassis",
		element: LazyLoad(lazy(() => import("@/page/Threedmodel/Chassis"))),
  },
  {
		path: "/threed/carbody",
		element: LazyLoad(lazy(() => import("@/page/Threedmodel/Carbody"))),
  },
  {
		path: "/threed/engine",
		element: LazyLoad(lazy(() => import("@/page/Threedmodel/Engine"))),
  },
  {
		path: "/threed/wholevehicle",
		element: LazyLoad(lazy(() => import("@/page/Threedmodel/Wholevehicle"))),
  },
  {
		path: "/threed/repairdoc",
		element: <Repairdoc />,
  },
	{
		path: '/threed/tpart',
		element: LazyLoad(lazy(() => import("@/page/Threedmodel/Part"))),
	},
	{
		path: '/threed/tmodel',
		element: LazyLoad(lazy(() => import("@/page/Threedmodel/Tmodel"))),
	},
	{
		path: '/threed/tcar',
		element: LazyLoad(lazy(() => import("@/page/Threedmodel/Tcar"))),
	},
	{
		path: "/threed/model",
		element: LazyLoad(lazy(() => import("@/page/Datastandard/Model/index"))),
  },
  {
		path: "/threed/model/detail",
		element: LazyLoad(lazy(() => import("@/page/Datastandard/Model/Detail"))),
  },


  

  // user
  {
		path: "/usermanger/user",
		element: LazyLoad(lazy(() => import("@/page/User/User"))),
  },
  {
		path: "/usermanger/role",
		element: LazyLoad(lazy(() => import("@/page/User/Role"))),
  },
  {
		path: "/usermanger/permission",
		element: LazyLoad(lazy(() => import("@/page/User/Permission"))),
  },
  {
		path: "/usermanger/buttonsList",
		element: LazyLoad(lazy(() => import("@/page/User/ButtonsList"))),
  },

  // 名称标准化
  {
    path: '/namestd/topname',
    element: LazyLoad(lazy(() => import("@/page/Namestd/Topname")))
  },
	{
    path: '/namestd/stdclass',
    element: LazyLoad(lazy(() => import("@/page/Namestd/Stdclass")))
  },
	{
		path: '/namestd/jhname',
		element: LazyLoad(lazy(() => import("@/page/Namestd/Bmname")))
	},
	{
		path: '/namestd/oeother',
		element: LazyLoad(lazy(() => import("@/page/Namestd/Oeothername")))
	},
	{
		path: '/namestd/grouplist',
		element: LazyLoad(lazy(() => import("@/page/Namestd/GroupList")))
	},
	{
		path: '/namestd/sensitive',
		element: LazyLoad(lazy(() => import("@/page/Namestd/Sensitive")))
	},





	// 数据运营
	{
		path: '/dataoperation/vinumodel',
		element: LazyLoad(lazy(() => import("@/page/Dataoperation/Vinumodel")))
	},
	{
		path: '/dataoperation/fourprice',
		element: LazyLoad(lazy(() => import("@/page/Dataoperation/Fourprice")))
	},
	{
		path: '/dataoperation/onlineservice',
		element: LazyLoad(lazy(() => import("@/page/Dataoperation/Onlineservice")))
	},
	{
		path: '/dataoperation/brandmange',
		element: LazyLoad(lazy(() => import("@/page/Dataoperation/Brandmange")))
	},
	{
		path: '/dataoperation/accountsource',
		element: LazyLoad(lazy(() => import("@/page/Dataoperation/Accountresources")))
	},

	// 维修知识
	{
		path: '/maintain/datamanage',
		element: <Datamanage/>
	},



	// 车型数据
	{
		path: '/cardata/original',
		element: LazyLoad(lazy(() => import("@/page/Cardata/OriginalData")))
	},
	{
		path: '/cardata/mappingrules',
		element: LazyLoad(lazy(() => import("@/page/Cardata/Mappingrules")))
	},
	{
		path: '/cardata/datacontrast',
		element: LazyLoad(lazy(() => import("@/page/Cardata/Datacontrast")))
	},
	{
		path: '/cardata/examine',
		element: LazyLoad(lazy(() => import("@/page/Cardata/Examine")))
	},


	// 翻译管理

	{
		path: '/translate/employ',
		element: LazyLoad(lazy(() => import("@/page/Translate/Employ")))
	},


	// 操作历史
	{
		path: '/ophistory',
		element: LazyLoad(lazy(() => import("@/page/Home/Ophistory")))
	},

]

nav.forEach((item: any) => {
	item.element = <KeepAlive name={item.path} cacheKey={item.path}>{item.element}</KeepAlive>
})

// console.log(nav)

const Router = () => {
	const routes = useRoutes(nav)
  return routes
}

export default Router