import axios from 'axios'

const dataOperationApi: any = {}



dataOperationApi.getNewVinList = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/getnew/list", {params})
}
dataOperationApi.addNewVin = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/cars/getnew/add", {params})
}
dataOperationApi.getBrandOptions = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/4s/price/update/brandlist", {params})
}
dataOperationApi.updateFourPrice = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/4s/price/update", params)
}

dataOperationApi.getOnlineService = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/wis/vingrouplist", {params})
}
dataOperationApi.getOnlineServiceStd = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/wis/group/mapstdname", {params})
}
dataOperationApi.updateOnlineServiceStd = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/wis/group/mapstdname/update", {params})
}
dataOperationApi.showdoc = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/api/v1/databzpt/wis/group/showdoc", {params})
}



dataOperationApi.getCarBrandlist = (params: {}): Promise<{}> => {
  return axios.get("/v1/operate/maintain/cars/brands/list", {params})
}
dataOperationApi.addCarBrand = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/maintain/cars/brands/add", params)
}
dataOperationApi.updateBrand = (params: {}): Promise<{}> => {
  return axios.post("/v1/operate/maintain/cars/brands/edit", params)
}

dataOperationApi.getAccountList = (params: {}): Promise<{}> => {
  return axios.post("/v1/grapcars/accountinfo/data/post/select/account_info", params)
}
dataOperationApi.addRpc = (params: {}): Promise<{}> => {
  return axios.post("/v1/grapcars/accountinfo/data/post/add/account_info", params)
}
dataOperationApi.updateRpcStatus = (params: {}): Promise<{}> => {
  return axios.post("/v1/grapcars/accountinfo/data/post/delete/account_info", params)
}





export default dataOperationApi