import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Modal, Segmented } from 'antd'
import { Outlet, useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import MainContext from "@/context/MainContext";
import Breadcrumb from "./Breadcrumb";
import SelectCar from "./components/SelectCar";


const Vincon: React.FC<any> = (props) => {
  const { store, setStore } = useContext(MainContext)
  const { vinData, selectCarKey } = store
  const {  } = props
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  const [tab, setTab] = useState<'vin' | 'car' | any>(localStorage.getItem('tab') || "vin")
  const [carVisible, setCarVisible] = useState<boolean>(false)
  const [vin, setVin] = useState<string>(vinData.vin || "")
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if(pathname === '/dataedit/group') {
      setVin("")
    }
  },[pathname])

  const search = () => {
    navigate(`/dataedit/vin?vin=${vin}`,{replace: true})
    setStore({ type: 'routekey', value: store.routekey + 1 })
    setStore({ type: 'vinData', value: {} })
    sessionStorage.removeItem("vinData")
    setStore({ type: "crumb", value: [] })
    sessionStorage.removeItem("crumb")
    setStore({ type: 'editKey', value: '' })
  }
  return (
    <div className="bgc-fff p10">
      {
        pathname.includes("/vin") ? (
          <Input.Search 
            placeholder="输入17位VIN车架号"
            size="large"
            style={{ width: '100%' }}
            allowClear
            value={vin} 
            onChange={(e: any) => {
              let _vin: string = e.target.value
              setVin(_vin ? _vin.toUpperCase() : '')
            }} 
            enterButton="车架号查询"
            onSearch={() => search()}
          />
        ): pathname.includes("/group") ? (
          <Button size="large" type="primary" style={{ width: '100%' }} onClick={() => setCarVisible(true)}>选择车型</Button>
        ): null
      }
      
      
      <Breadcrumb  />
      <Outlet key={store.routekey}  />


      <Modal
        title="车型选择"
        width={'100vw'}
        open={carVisible}
        onCancel={() => setCarVisible(false)}
        footer={null}
        maskClosable={false}
      >
        <SelectCar
          key={selectCarKey}
          onCancel={() => setCarVisible(false)}
        />
      </Modal>
    </div>
  )
}

export default Vincon