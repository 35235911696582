import React, { useEffect, useState, useRef, useContext } from "react";
import { Spin, Tooltip, Button, Input, Modal, InputNumber, Image, message } from 'antd'
import vinApi from "@/api/vinApi";
import css from './index.module.scss'
import axios from "axios";
import { useActionData, useNavigate } from 'react-router-dom'
import MainContext from "@/context/MainContext";
import { CloseCircleOutlined } from '@ant-design/icons'
import Upload from "@/components/Upload";
import CryptoJS from 'crypto-js'


const type = ['原厂结构', '标准结构']

const SelectCar: React.FC<any> = (props) => {
  const { store, setStore } = useContext(MainContext)
  const { vin, onCancel } = props
  const navigate = useNavigate()
  const [configData, setConfigData] = useState<any>({})

  const [catalog, setCatalog] = useState<any>({acronym_keys: [], data: []})
  const [acronymActive, setAcronymActive] = useState<any>('全部')
  
  const [breadActive, setBreadActive] = useState<any>([])
  const [title, setTitle] = useState<any>([])
  const [typeActive, setTypeActive] = useState<string>('原厂结构')
  const [models, setModels] = useState<any>([])
  const [catalogVisible, setCatalogVisible] = useState<boolean>(false)
  const [imgLoading, setImgLoading] = useState<boolean>(false)
  const [catalogLoading, setCatalogLoading] = useState<boolean>(false)

  const [ek, setEk] = useState<'brand' | 'car' | ''>("")
  const [submitLoading, setSubmitLoading] = useState<any>({

  })

  const [ebrands, setEbrands] = useState<any>([])

  const [open, setOpen] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({})

  const [careIndex, setCareIndex] = useState<number>(-1)
  const [ecars, setEcars] = useState<any>([])

  const [change, setChange] = useState<boolean>(false)
  const [imgOpen, setImgOpen] = useState<boolean>(false)
  const [imgSubmitLoading, setImgSubmitLoading] = useState<boolean>(false)
  const [uploadImgs, setUploadImgs] = useState<any>([])
  const [imgItem, setImgItem] = useState<any>({})
  const [carImgList, setCarImgList] = useState<any>([])

  const [endItem, setEndItem] = useState<any>({})

  let modelsCancelToken: any = useRef<any>(null)

  useEffect(() => {
    getBrand()
    vin && vinApi.getVinConfig({vin}).then((res: any) => {
      if(res.code === 1) {
        setConfigData(res.data)

      }
    })
  },[vin])

  useEffect(() => {
    getCarImgs()
  },[imgItem])


  const getBrand = () => {
    axios.all([vinApi.getBrand()]).then(axios.spread((brandResult) => {

      if(brandResult.code === 1){
        let _data: any = []
        Object.keys(brandResult.data).forEach((index: any) => {
          _data = [..._data, ...brandResult.data[index]]
        })
        brandResult.data = _data
        console.log(brandResult)
        const catalogbrandcode = window.localStorage.getItem('catalogbrandcode')
        let temp: any = []
        let data: any = []
        let brand = [].map((item: any)=>{return item.brandCode})
        const max = brandResult.data.length - 1
        for(let i = max; i > -1; i--){
          let britem = brandResult.data[i]
          let index = brand.indexOf(britem.brandcode)
          
          if(temp.indexOf(britem.brandcode) === -1){
            temp.push(britem.brandcode)
            if(catalogbrandcode && catalogbrandcode === britem.brandcode){
              // britem.count = 1000000
              data = [...data, ...brandResult.data.splice(i, 1)]
            }else if(index > -1){
              // britem.count = countResult.data[index].count
              data = [...data, ...brandResult.data.splice(i, 1)]
            }
          }else {
            brandResult.data.splice(i, 1)
          }
          
        }
        data.sort((a: any, b: any)=>{return b.count - a.count})
        brandResult.data = [...data, ...brandResult.data]
        brandResult.acronym_keys.unshift('全部')
        setCatalog(brandResult)
      }
    }))
  }

  const getTitle = (item: any) => {
    // setBreadActive([item])
    setTitle([])
    window.localStorage.setItem('catalogbrandcode', item.brandcode)
    let param: any = {
      brandCode: item.brandcode
    }
    if(typeActive === '标准结构'){
      param.model = '007'
    }
    setCatalogLoading(true)
    vinApi.getTitle(param).then((result: any)=>{
      if(result.code === 1){
        setTitle(result.data)
        setModels([])
        getModels(item, 0, [])
      }else {
        setCatalogLoading(false)
      }
      
    })
  }

  const getModels = (item: any, level: number, tempBread?: any) => {
    if(modelsCancelToken.current){
      modelsCancelToken.current.abort()
    }
    modelsCancelToken.current = new AbortController()
    item.type = 'config'
    item.title = title[level - 1] || ''
    if(item.has_child || level === 0){
      let param: any = {}
      if(level){
        param = {
          level,
          brandCode: item.brandCode,
          name: item.name,
          full_condition_path: item.full_condition_path,
          next_path_md5: item.next_path_md5
        }
      }else {
        param = {
          level,
          brandCode: item.brandcode
        }
      }
      // if(typeActive === '标准结构'){
      //   param.model = '007'
      // }
      setCatalogLoading(true)
      vinApi.getModels(param, {signal: modelsCancelToken.current.signal}).then((result: any)=>{
        modelsCancelToken.current = null
        setCatalogLoading(false)
        if(result.code === 1 || result.code === 2){
          let tempModels = level === 0 ? [] : [...models]
          let tempBreadActive = tempBread || [...breadActive]
          let tempTitle = [...title]

          item.key = ''

          if(tempModels.length > level){
            // item.key = tempBreadActive[level].key
            tempModels.splice(level, tempModels.length - level)
            tempBreadActive.splice(level, tempBreadActive.length - level)
          }
          if(level){
            tempTitle[level] = result.title
            setTitle(tempTitle)
          }
          tempBreadActive.push(item)
          tempModels.push(result.data)

          setBreadActive(tempBreadActive)
          setModels(tempModels)
          
        }
        
      })
    }else {

      let tempModels = level === 0 ? [] : [...models]
      let tempBreadActive = tempBread || [...breadActive]
      console.log(tempModels, level)
      if(tempModels.length >= level){
        tempModels.splice(level, tempModels.length - level)
        tempBreadActive.splice(level, tempBreadActive.length - level)
      }
      
      tempBreadActive.push(item)

      setBreadActive(tempBreadActive)
      setModels(tempModels)

      setEndItem(item)
      return false 

      
    }
  }
  const gotoGroup = (item = endItem) => {

    let tempCrumb: any = [...breadActive]
    tempCrumb.push(item)
    tempCrumb.unshift({
      type: 'logo',
      img: catalog.IMG_DNS + tempCrumb[0].img
    })
    delete tempCrumb[1].type

    setStore({ type: 'routekey', value: store.routekey + 1 })
    setStore({ type: 'vinData', value: {} })
    sessionStorage.removeItem("vinData")
    setStore({ type: 'editKey', value: '' })

    setStore({value: tempCrumb, type: 'crumb'})
    
    window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
    onCancel()
    navigate(`/dataedit/group?mcid=${item.mcid.replace(/\=/g, '%3D')}&brandcode=${item.brandCode}&num=0`)
  }
  const search = (val: string, index: number) => {
    let tempBreadActive = [...breadActive]
    if(!tempBreadActive[index]){
      tempBreadActive[index] = {}
    }
    tempBreadActive[index].key = val
    setBreadActive(tempBreadActive)
  }

  const changeBreadActive = (index: number) => {
    let tempBreadActive = [...breadActive]
    tempBreadActive.splice(index, tempBreadActive.length - index)
    setBreadActive(tempBreadActive)
  }

  const toeditBrand = () => {
    setEk("brand")
    setEbrands(JSON.parse(JSON.stringify(catalog.data)))
    setAcronymActive("全部")
  }

  const toeditCar = (index: number) => {
    setCareIndex(index)
    let _ecars = models[index] ? JSON.parse(JSON.stringify(models[index])) : []
    setEcars(_ecars)
  }
  const addEcarsItem = (mindex: number) => {
    let brandData = breadActive[0] || {}
    let { brandcode, brandname } = brandData
    let level = 1
    let has_child = 1
    if(models[mindex] && models[mindex][0]) {
      has_child = models[mindex][0].has_child
    }
    let full_condition_path = '/' + brandcode
    let lastItem = breadActive[mindex]
    if(lastItem && mindex) {
      full_condition_path = lastItem.full_condition_path
      level = lastItem.level + 1
    }
    setActiveItem({
      level, 
      has_child, 
      code: '', 
      full_condition_path, 
      last_full_condition_path: full_condition_path,
      img: '', 
      label: '', 
      // mcid, 
      name: '', 
      next_path_md5: '',
      brandCode: brandcode,
      brand: brandname,
      cid: ''
    })
    setOpen(true)
    
  }
  const delEcarsItem = (event: any, index: number) => {
    event.stopPropagation()
    let _ecars: any = JSON.parse(JSON.stringify(ecars))
    _ecars.splice(index, 1)
    setEcars(_ecars)
  }
  const submitCar = (index: number, type: 'submit' | 'save') => {
    let lastItem = breadActive[index]
    console.log(lastItem)
    const { brandCode, brandcode, level = 0, name, full_condition_path } = lastItem
    let params = {
      brandCode: brandcode || brandCode || '',
      name: index ? name: '',
      level,
      full_condition_path,
      status: type === 'submit' ? 1 : 0,
      models_list: ecars
    }
    // return false
    setSubmitLoading({...submitLoading, [type]: true})
    vinApi.updateCar(params).then((res: any) => {
      setSubmitLoading({...submitLoading, [type]: false})
      if(res.code === 1) {
        setCareIndex(-1)
        message.success("操作成功！")
        let tempBread = breadActive.slice(0, index + 1)
        getModels(lastItem, level, tempBread)
      }
    })
  }

  const toUploadImg = (index: number) => {
    let item = breadActive[index]
    let items = breadActive.slice(0, index + 1)
    let title = items.map((it: any, i: number) => {
      return (it.show_name || it.name) + (items.length !== i + 1 ? '>' :'')
    }).join("")
    setUploadImgs([])
    setImgOpen(true)
    setImgItem({...item, title})
  }

  const getCarImgs = () => {
    let params ={
      ...imgItem,
      brandCode: imgItem.brandcode || imgItem.brandCode || "",
      level: imgItem.level || 0
    }
    vinApi.getCarImgs(params).then((res: any) => {
      if(res.code === 1) {
        setCarImgList(res.data)
      }
    })
  }

  const submitImg = (url: string) => {
    console.log(imgItem)
    let params = {
      brandCode: imgItem.brandcode || imgItem.brandCode || "",
      name: imgItem.name,
      level: imgItem.level || 0,
      full_condition_path: imgItem.full_condition_path || "",
      status: 1,
      image_url: url
    }
    setImgSubmitLoading(true)
    vinApi.uploadCarImg(params).then((res: any) => {
      setImgSubmitLoading(false)
      if(res.code === 1) {
        message.success("上传成功！")
        setCarImgList([...carImgList, res.data])
      }
    })
  }

  const delImg = (_id: any, index: number) => {
    let params = {
      brandCode: imgItem.brandcode || imgItem.brandCode || "",
      name: imgItem.name,
      level: imgItem.level || 0,
      full_condition_path: imgItem.full_condition_path || "",
      status: 1,
      img_id: _id
    }
    setImgSubmitLoading(true)
    vinApi.updateCarImgs(params).then((res: any) => {
      setImgSubmitLoading(false)
      if(res.code === 1) {
        message.success("删除成功！")
        carImgList.splice(index, 1)
        setCarImgList(carImgList)
      }
    })
  }

  console.log(breadActive,models)
  return (
      <Spin spinning={catalogLoading}>
        <div className={css.catalogModal}>
            <div className={css.box}>
              <div className={css.top + ' flex-b-c'}>
                <div className={css.acronym}>
                  {
                    breadActive.length ? (
                      <ul>
                        {
                          breadActive.map((item: any, index: number)=>{
                            return (
                              <>
                                <li onClick={()=>changeBreadActive(index)}>
                                  {
                                    item.name.length > 36 ? (
                                      <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.name} overlayStyle={{width: 222}} >
                                        <span>{item.show_name? item.show_name.substring(0, 36) : item.name.substring(0, 36)}...</span>
                                      </Tooltip>
                                    ): (
                                      <>{item.show_name || item.name}</>
                                    )
                                  }
                                </li>
                                {
                                  breadActive.length !== index + 1 ? <li>&gt;</li> : null
                                }
                                
                              </>
                            )
                          })
                        }
                      </ul>
                    ): (
                      <ul>
                        {
                          catalog.acronym_keys.map((item: string, index: number)=>{
                            return (
                              <li onClick={()=>setAcronymActive(item)} key={index} className={acronymActive === item ? css.active : ''}>{item}</li>
                            )
                          })
                        }
                      </ul>
                    )
                  }
                </div>
                {
                  breadActive.length === models.length + 1 ? (
                    <div className="flex-s-c" style={{ justifyContent: 'flex-end' }}>
                      <Button className="mr10" type="primary" ghost onClick={() => toUploadImg(breadActive.length - 1)}>上传图片</Button>
                      {/* <Button type="primary" onClick={() => gotoGroup()} >去查询</Button> */}
                    </div>
                  ): null
                }
              </div>
              
              
              <div className={css.content}>
                {
                  breadActive.length ? (
                    <div className={css.model}>
                      {
                        title.map((item: string, index: number)=>{
                          return (
                            <div className={css.item} key={index}>
                              <h5 className="flex-b-c">
                                <div>{item}</div>
                                <div>
                                  {/* {
                                    breadActive.length && models[index] && index === models.length - 1 ? careIndex === index ? (
                                      <>
                                        <Button size="small" className='mr5' onClick={() => {setCareIndex(-1); setEcars([])}}>取消</Button>
                                        <Button onClick={() => submitCar(index, 'save')} size="small" loading={submitLoading['save'] ? true : false} className='mr5' type="primary" ghost >保存</Button>
                                        <Button onClick={() => submitCar(index, 'submit')} className={css['submit']} size="small" loading={submitLoading['submit'] ? true : false} type="primary" >发布</Button>
                                      </>
                                    ): (
                                      <Button onClick={() => toeditCar(index)} size="small" icon={<i className='iconfont icon-bianji' />} type="primary" style={{ background: '#FF7A00', borderColor: '#FF7A00' }}>编辑</Button>
                                    ): null
                                  } */}
                                  
                                  {
                                    breadActive[index] ? (
                                      <Button onClick={() => toUploadImg(index)} size="small" ghost type="primary">上传图片</Button>
                                    ): null
                                  }
                              
                                </div>
                              </h5>
                              {
                                models[index] && models[index].length > 10  ? (
                                  <input disabled={careIndex === index} type="text" value={breadActive[index]?.key || ''} placeholder="Search" onChange={(e)=>search(e.target.value, index)} />
                                ): null
                              }
                              <ul style={{ height: models[index] && models[index].length > 10 ? 'calc(100% - 116px)' : 'calc(100% - 74px)' }}>
                                {
                                  careIndex !== index ? models[index]?.map((model: any, i: number)=>{
                                    return (
                                      !breadActive[index] || !breadActive[index].key || model.name.toLowerCase().indexOf(breadActive[index].key.toLowerCase()) !== -1 ? (
                                        <li className={breadActive[index + 1]?.name === model.name ? (model.click === 0 ? (css.grey + ' ' + css.active) : css.active) : (model.click === 0 ? css.grey : '') } key={i} onClick={()=>getModels(model, model.level)}>
                                          {model.name}
                                        </li>
                                      ): null
                                    )
                                  }): (
                                    <>
                                      {
                                        ecars.map((model: any, i: number) => {
                                          return (
                                            <li key={i} className="flex-b-c" onClick={() => {setActiveItem(model); setOpen(true)}}>
                                              {model.name}
                                              <CloseCircleOutlined  
                                                className={'icon-close ml5'} 
                                                onClick={(e: any) => delEcarsItem(e, i)}
                                              />
                                            </li>
                                          )
                                        })
                                      }
                                      <li 
                                        className="center p-color"  
                                        style={{ fontSize: 22 }}
                                        onClick={() => {
                                          addEcarsItem(index)
                                        }}
                                      >
                                        +
                                      </li>
                                    </>
                                    
                                  )
                                }
                              </ul>
                              <div className={css['edit'] + ' plr10'}>
                                {
                                  breadActive.length && models[index]  ? careIndex === index ? (
                                    <>
                                      <Button size="small" className='mr5' onClick={() => {setCareIndex(-1); setEcars([])}}>取消</Button>
                                      <Button onClick={() => submitCar(index, 'save')} size="small" loading={submitLoading['save'] ? true : false} className='mr5' type="primary" ghost >保存</Button>
                                      <Button onClick={() => submitCar(index, 'submit')} className={css['submit']} size="small" loading={submitLoading['submit'] ? true : false} type="primary" >发布</Button>
                                    </>
                                  ): (
                                    <Button onClick={() => toeditCar(index)} size="small" icon={<i className='iconfont icon-bianji' />} type="primary" style={{ background: '#FF7A00', borderColor: '#FF7A00' }}>编辑</Button>
                                  ): null
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  ): (
                      <div className={css.brands} id="brands">
                        <ul>
                          {
                            catalog.data.map((item: any)=>{
                              return (
                                acronymActive === '全部' || acronymActive === item.acronym || acronymActive === item.area_name ? (
                                  <li className={item.count ? css.active : ''} onClick={()=>{getTitle(item)}}>
                                    <img src={`${catalog.IMG_DNS}${item.img}`} />
                                    <p>{item.name}</p>
                                  </li>
                                ): null
                              )
                            })
                          }
                        </ul>
                      </div>
                  )
                }
              </div>
            </div>
        </div>


        <Modal
          title='编辑'
          open={open}
          onCancel={() => setOpen(false)}
          footer={null}
          width={700}
        >
        <div className="mt20">
          <div className='flex-s-c'>
            <label style={{ width: 150 }} htmlFor=""><span className="red">*</span>name：</label>
            <Input style={{ flex: 1 }} value={activeItem.name} 
              onChange={(e: any) => { activeItem['name'] = e.target.value; activeItem['full_condition_path'] = activeItem['last_full_condition_path'] + '/' + e.target.value; activeItem['fixed'] = 1; setChange(c => !c) }} 
            />
          </div>
          <div className='flex-s-c mt10'>
            <label style={{ width: 150 }} htmlFor=""><span className="red">*</span>full_condition_path：</label>
            <Input  
              style={{ flex: 1 }} 
              value={activeItem.full_condition_path} 
              onChange={(e: any) => 
                { 
                  let str = e.target.value
                  activeItem['full_condition_path'] = str; 
                  activeItem['fixed'] = 1; 
                  setChange(c => !c) 
                }
              } 
            />
          </div>
          {/* <div className='flex-s-c mt10'>
            <label style={{ width: 150 }} htmlFor=""><span className="red">*</span>next_path_md5：</label>
            <Input style={{ flex: 1 }} value={activeItem.next_path_md5} onChange={(e: any) => { activeItem['next_path_md5'] = e.target.value; activeItem['fixed'] = 1; setChange(c => !c) }} />
          </div> */}
          <div className='flex-s-c mt10'>
            <label style={{ width: 150 }} htmlFor=""><span className="red">*</span>has_child：</label>
            <InputNumber  style={{ flex: 1 }} value={activeItem.has_child} onChange={(e: any) => { activeItem['has_child'] = e || 0; activeItem['fixed'] = 1; setChange(c => !c) }} />
          </div>
          <div className='flex-s-c mt10'>
            <label style={{ width: 150 }} htmlFor=""><span className="red">*</span>level：</label>
            <InputNumber style={{ flex: 1 }} value={activeItem.level} onChange={(e: any) => { activeItem['level'] = e || 0; activeItem['fixed'] = 1; setChange(c => !c) }} />
          </div>
          <div className='flex-s-c mt10'>
            <label style={{ width: 150 }} htmlFor=""><span className="red">*</span>{activeItem._id ? 'mcid' : 'cid'}：</label>
            <Input style={{ flex: 1 }} 
              value={activeItem._id ? activeItem.mcid : activeItem.cid} 
              onChange={(e: any) => { 
                if(activeItem._id) {
                  activeItem['mcid'] = e.target.value; 
                }else {
                  activeItem['cid'] = e.target.value; 
                }
                activeItem['fixed'] = 1; 
                setChange(c => !c) 
              }} 
            />
          </div>
          
          <div className='flex-s-c mt10'>
            <label style={{ width: 150 }} htmlFor="">img：</label>
            <div className='flex-s-c'>
              <Upload
                successUrl={(url) => {
                  activeItem['img'] = url;
                  setChange(c => !c)
                }}
              >
                <div className='pointer flex-c-c m5' style={{ width: 70, height: 70, border: '1px solid #f8f8f8', fontSize: 28 }}>
                  +
                </div>
              </Upload>
              {
                activeItem.img ? (
                  <span className='flex ml5 relative'>
                    <Image src={activeItem.img} width={70} height={70} />
                    <CloseCircleOutlined  
                      className={'icon-close ml5'} 
                      onClick={(e: any) => {
                        activeItem['img'] = '';
                        setChange(c => !c)
                      }}
                    />
                  </span>
                ): null
              }
            </div>
          </div>
          
          <div className='right mt20'>
            <Button onClick={() => setOpen(false)}>关闭</Button>
            {
              !activeItem._id ? (
                <Button className='ml10' type="primary" 
                  onClick={() => {
                    console.log(activeItem)
                    // return false
                    if(!activeItem.name) {
                      message.warning("请先填写必填项！")
                      return false
                    }
                    setOpen(false)
                    setEcars([...ecars, {...activeItem}])
                  }}
                >确定</Button>
              ): null
            }
          </div>
        </div>
      </Modal>

      <Modal
        title={`上传图片(${imgItem.title})`}
        open={imgOpen}
        width={800}
        onCancel={() => setImgOpen(false)}
        
        footer={null}
      >
        <div className='flex-s-c' style={{ flexWrap: 'wrap', maxHeight: '80vh', overflow: 'scroll' }}>
          <Upload
            successUrl={(url) => {
              // uploadImgs.push(url)
              submitImg(url)
              // setChange(c => !c)
            }}
          >
            <div className='pointer flex-c-c m5' style={{ width: 70, height: 70, border: '1px solid #f8f8f8', fontSize: 28 }}>
              +
            </div>
          </Upload>
          {
            carImgList.map((item: any, index: number) => {
              let img = item.image_url
              return (
                <span className='flex m5 relative' key={img}>
                  <Image src={img} width={70} height={70} />
                  <CloseCircleOutlined  
                    className={'icon-close ml5'} 
                    onClick={(e: any) => {
                      delImg(item._id, index)
                    }}
                  />
                </span>
              )
            })
          }
        </div>
      </Modal>

      {
        breadActive.length === models.length + 1 ? (
          <div className="flex-s-c mt20" style={{ justifyContent: 'flex-end' }}>
            {/* <Button className="mr10" type="primary" ghost onClick={() => toUploadImg(breadActive.length)}>上传图片</Button> */}
            <Button type="primary" onClick={() => gotoGroup()} >去查询</Button>
          </div>
        ): null
      }
      

      </Spin>
  )
}

export default SelectCar