import React, { useState, useReducer, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'


import mainScss from './main.module.scss'
import { RightOutlined, DownOutlined } from '@ant-design/icons'
import context from '@/context/MainContext'
import accountApi from '@/api/accountApi'

const data: any = [
  {
    title: '首页',
    icon: 'icon-shouye',
    path: '/home'
  },
  {
    title: '数据标准化',
    icon: 'icon-shujubiaozhun',
    path: '/datastandard',
    switchOpen: false,
    children: [
      {
        title: '车型',
        path: '/datastandard/model'
      },
      {
        title: '底盘',
        path: '/datastandard/hang'
      },
      {
        title: '发动机',
        path: '/datastandard/engine'
      },
      {
        title: '零件',
        path: '/datastandard/part'
      }
    ]
  },
  {
    title: '3D模型数据',
    icon: 'icon-shujubiaozhun',
    path: '/threed',
    switchOpen: false,
    children: [
      {
        title: '车身',
        path: "/threed/carbody"
      },
      {
        title: '底盘',
        path: "/threed/chassis"
      },
      {
        title: '发动机',
        path: "/threed/engine"
      },
      {
        title: '整车',
        path: "/threed/wholevehicle"
      }
    ]
  },
  {
    title: '用户管理',
    icon: 'icon-yonghuguanli',
    path: '/usermanger',
    switchOpen: false,
    children: [
      {
        title: '用户列表',
        path: '/usermanger/user'
      },
      {
        title: '角色权限',
        path: '/usermanger/role'
      },
      {
        title: '权限列表',
        path: '/usermanger/permission'
      },
      {
        title: '按钮列表',
        path: '/usermanger/buttonsList'
      }
    ]
  }
]

const Menu: React.FC = () => {
  const { store, setStore } = useContext(context)
  const { userInfo } = store
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [menu, setMenu] = useState<any>([])
  const [change, setChange] = useState<boolean>(false)

  
  useEffect(() => {
    getMenu()
    // if(localStorage.getItem("token") && userInfo.role_id) {
    //   getMenu()
    // }
  },[userInfo])

  const getMenu = () => {
    accountApi.getRolePermission({ role_id: userInfo.role_id }).then((res: any) => {
      if(res.code === 1) {
        let firstData: any = (res.data ? res.data : []).filter((it: any) => !it.permission_name && !it.permission_url)
        let _data: any = (res.data ? res.data : []).filter((it: any) => it.id && it.parent_permission_id && it.permission_name && it.is_permission && it.permission_type)
        let _list: any = []
        let _writeAuth: any = {}
        let _menuData: any = []
        let _historePer: any = {}
        _data.forEach((item: any) => {
          _menuData.push({
            label: item.permission_name,
            key: item.permission_url,
          })
          if(item.permission_url === '/ophistory') {
            if(item.permission_type === 'all' || item.permission_type === 'write') {
              _historePer = {
                write: true,
                read: true
              }
            }else {
              _historePer = {
                write: false,
                read: true
              }
            }
          }
          if(item.permission_type === 'all' || item.permission_type === 'write') {
            _writeAuth[item.permission_url] = true
          }else {
            _writeAuth[item.permission_url] = false
          }
          let i = _list.findIndex((it: any) => it.parent_permission_id === item.parent_permission_id)
          let ritem: any = {...item, title: item.permission_name, path: item.permission_url}
          if(i === -1) {
            _list.push({
              name: item.parent_permission_name,
              parent_permission_id: item.parent_permission_id,
              children: [ritem]
            })
          }else {
            _list[i].children.push(ritem)
          }
        })
        setStore({ type: 'historePer', value: _historePer })
        _list.forEach((item: any) => {
          let i = firstData.findIndex((it: any) => it.parent_permission_id === item.parent_permission_id)
          if(i !== -1) {
            let ritem: any = firstData[i]
            item.title = ritem.parent_permission_name
            item.path = ritem.parent_path
            item.icon = ritem.parent_icon
          }
        })
        let other = ['首页', '数据标准化', '车型数据', '译码管理', '数据运营', '3D模型数据', '数据编辑', '翻译管理', '用户管理']
        _list.sort((a: any, b: any) => {
          let ai: number = other.indexOf(a.title)
          let bi: number = other.indexOf(b.title)
          return ai - bi
        })
        setMenu(_list.filter((it: any) => it.title !== '操作历史'))
        setStore({ value: _writeAuth, type: 'writeAuth' })
        localStorage.setItem("writeAuth", JSON.stringify(_writeAuth))
        setStore({ value: _menuData, type: 'menuData' })
        localStorage.setItem('menuData', JSON.stringify(_menuData))
      }
    })
  }


  const gotoNavigate = (item: any, index: number) => {
    let _menu = JSON.parse(JSON.stringify(menu))
    if(item.children && item.children.length) {
      let paths = item.children.map((it: any) => it.path)
      if(paths.includes(pathname)) {
        _menu[index].switchOpen = !_menu[index].switchOpen
        setMenu(_menu)
      }else {
        _menu.forEach((it: any, i: number) => {
          _menu[i].switchOpen = false
          if(index === i) {
            _menu[index].switchOpen = true
          }
        })
        setMenu(_menu)
        clearCarVinData(item.children[0].path)
        navigate(item.children[0].path)
      }
    }else {
      clearCarVinData(item.path)
      navigate(item.path)
    }
  }

  const clearCarVinData = (path: string) => {
    if(path && path.includes('/dataedit')) {
      setStore({ type: 'routekey', value: store.routekey + 1 })
      setStore({ type: 'vinData', value: {} })
      sessionStorage.removeItem("vinData")
      setStore({ type: "crumb", value: [] })
      sessionStorage.removeItem("crumb")
      setStore({ type: 'selectCarKey', value: store.selectCarKey + 1 })
    }
  }

  return (
    <div className={mainScss.menu} >
      <div>
        {
          menu.map((item: any, i: number)=>{
            return (
              <div key={i} className={mainScss.item}>
                <h2 className={pathname.indexOf(item.path) === 0 ? mainScss.active : ''} onClick={() => item.path && gotoNavigate(item, i)}>
                  <span className={`iconfont ${item.icon}`}></span>
                  <em>{item.title}</em>
                  {
                    item.children ? (item.switchOpen ? <DownOutlined /> : <RightOutlined/>) : null
                  }
                </h2>
                {
                  item.switchOpen && item.children ? (
                    <ul>
                      {
                        item.children.map((child: any, index: number)=>{
                          return <li key={index} className={pathname === child.path ? mainScss['active'] : ''} onClick={() => {navigate(child.path); clearCarVinData(item.path)}}><span>{child.title}</span></li>
                        })
                      }
                    </ul>
                  ): null
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Menu