import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Main from '@/page/Main'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/locale/zh_CN'
import { IntlProvider } from 'react-intl'
import zh from './locale/zh'

import './index.scss'

import { AliveScope } from 'react-activation'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter basename="/">
    <IntlProvider locale={zh.locale} messages={zh.messages}>
      <ConfigProvider
        locale={zhCN}
      >
        <Main />
      </ConfigProvider>
    </IntlProvider>
    
  </BrowserRouter>
  // <React.StrictMode>
  //   <Main />
  // </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
